import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import { PageContent, PageMetaTags } from 'Common'
import { withContext } from 'Context'
import './style.scss'
import { Grid, Loader } from 'semantic-ui-react'
import _ from 'lodash'
import { useCourseBundle } from 'Hooks/useCourseBundle'
import { CourseBundleItem } from 'Common/CourseBundleItem'

const PAGE_NAME = 'Course Bundles'

const CourseBundles = ({ setBreadcrumbs }) => {
	const { courseBundleOptions, loading, onBundleSelection } = useCourseBundle()

	useEffect(() => {
		if (courseBundleOptions.length === 1) {
			onBundleSelection(courseBundleOptions[0].key)
		}
	}, [courseBundleOptions])
	useEffect(() => {
		setBreadcrumbs([
			{
				name: 'Learners',
				path: '/learners',
			},
			{
				name: PAGE_NAME,
			},
		])
	}, [])
	return (
		<>
			<PageMetaTags title={PAGE_NAME} />
			<PageContent className="users-page">
				<PageContent.Content>
					<div className="users-list">
						<div className="page-title">
							<h1>Course Bundles</h1>
						</div>
						<p>Choose bundle to add course</p>
						{loading ? (
							<Loader active />
						) : courseBundleOptions.length > 1 ? (
							<div className="tabs-view">
								<Grid columns={5}>
									<Grid.Row className="course-bundle-row">
										{_.map(courseBundleOptions, bundle => (
											<CourseBundleItem
												key={bundle.key}
												bundle={bundle}
												onBundleSelection={onBundleSelection}
											/>
										))}
									</Grid.Row>
								</Grid>
							</div>
						) : (
							<p>You dont have any subscription or course bundle</p>
						)}
					</div>
				</PageContent.Content>
			</PageContent>
		</>
	)
}

CourseBundles.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
}

export default withContext(
	([
		{
			users: { customFilter },
		},
	]) => ({
		// state
		customFilter,
	}),
	withBreadcrumbs(CourseBundles),
)
