import React from 'react'
import PropTypes from 'prop-types'
import { Button, Grid } from 'semantic-ui-react'

import './style.scss'

const CourseBundleItem = ({ bundle, onBundleSelection }) => {
	const isDisabled = !onBundleSelection
	const buttonProps = !isDisabled && {
		onKeyPress: () => onBundleSelection(bundle.key),
		onClick: () => onBundleSelection(bundle.key),
		role: 'button',
		tabIndex: '0',
	}

	return (
		<Grid.Column className={`custom-column-div ${!isDisabled && 'pointer'}`}>
			<div className="custom-column" {...buttonProps}>
				<div className="tag-listing">{bundle.value}</div>
				<div className="license-subscription">
					<h3>License used:</h3>
					<p>{bundle.current_users_count}</p>
				</div>
				<div className="license-subscription">
					<h3>Total License:</h3>
					<p>{bundle.max_users_count}</p>
					{!isDisabled && <Button className="bundle-proceed" size="small" content="Proceed" />}
				</div>
			</div>
		</Grid.Column>
	)
}

CourseBundleItem.propTypes = {
	bundle: PropTypes.object.isRequired,
	onBundleSelection: PropTypes.func,
}

CourseBundleItem.defaultProps = {
	onBundleSelection: undefined,
}

export { CourseBundleItem }
