import React, { memo, useCallback, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Loader } from 'semantic-ui-react'
import moment from 'moment'
import _ from 'lodash'
import SessionTable from './SessionTable'

const SessionList = ({
	filter,
	onFiltered,
	sessionData,
	loading,
	selectedUsers,
	setSelectedUsers,
	refetchActivityReportSession,
	setRefetchLoader,
	disableLastActive,
}) => {
	const [activeActionsSelect, setActiveActionsSelect] = useState(true)
	const sessionList = useMemo(() => {
		const currentDate = moment()

		return _.map(sessionData && sessionData.sessionsActivityList.data.data, sessionList => {
			sessionList.name = `${sessionList.first_name} ${sessionList.last_name}`
			sessionList.sessionName = sessionList.session.title
			sessionList.signed_off = sessionList.session.date_completed
				? moment(sessionList.session.date_completed).format('DD/MM/YYYY')
				: null
			sessionList.due = sessionList.session.due
			sessionList.attendance_status = sessionList.session.attendance_status
				? sessionList.session.attendance_status == 1
					? 'Complete'
					: 'Not Complete'
				: null
			sessionList.last_active = sessionList.session.lastActive
				? currentDate.diff(sessionList.session.lastActive.created_at, 'days')
				: null
			sessionList.progress = sessionList.session.progress
			sessionList.is_booked = sessionList.session.is_booked

			return sessionList
		})
	}, [sessionData])

	const setPage = useCallback(
		page => {
			onFiltered({ ...filter, page })
		},
		[onFiltered, filter],
	)

	const handleSortTable = useCallback(
		e => {
			const paramName = _.get(e, 'currentTarget.dataset.sortParam', e)

			const newActiveParam = { name: filter.sort_param, order: filter.sort_direction }

			if (_.isEqual(newActiveParam.name, paramName)) {
				switch (newActiveParam.order) {
					case 'asc':
						newActiveParam.order = 'desc'
						break
					case 'desc':
						newActiveParam.name = ''
						newActiveParam.order = 'asc'
						break
				}
			} else {
				newActiveParam.name = paramName
				newActiveParam.order = 'asc'
			}

			onFiltered({ ...filter, sort_param: newActiveParam.name, sort_direction: newActiveParam.order })
		},
		[filter, onFiltered],
	)

	useEffect(() => {
		if (!!selectedUsers.length && activeActionsSelect) setActiveActionsSelect(false)
		if (!selectedUsers.length && !activeActionsSelect) setActiveActionsSelect(true)
	}, [selectedUsers])

	if (loading) return <Loader active />
	return (
		<>
			<div className="users-list">
				<SessionTable
					usersList={sessionList}
					page={filter.page}
					setPage={setPage}
					perPage={sessionData.sessionsActivityList.data.per_page}
					totalCount={sessionData.sessionsActivityList.total_count}
					length={sessionData.sessionsActivityList.data.total}
					handleSortTable={handleSortTable}
					selectedUsers={selectedUsers}
					setSelectedUsers={setSelectedUsers}
					setRefetchLoader={setRefetchLoader}
					refetchActivityReportSession={refetchActivityReportSession}
					activeSortParam={{
						name: filter.sort_param || '',
						order: filter.sort_direction || 'asc',
					}}
					disableLastActive={disableLastActive}
				/>
			</div>
		</>
	)
}

SessionList.propTypes = {
	filter: PropTypes.object.isRequired,
	onFiltered: PropTypes.func.isRequired,
	sessionData: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	selectedUsers: PropTypes.array.isRequired,
	setSelectedUsers: PropTypes.func.isRequired,
	setRefetchLoader: PropTypes.bool.isRequired,
	refetchActivityReportSession: PropTypes.func.isRequired,
	disableLastActive: PropTypes.bool,
}

SessionList.defaultProps = {
	disableLastActive: false,
}

export default memo(SessionList)
