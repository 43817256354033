import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Grid, Loader, Popup } from 'semantic-ui-react'
import PropTypes from 'prop-types'

import { GET_MANAGER_DASHBOARD } from 'Queries/dashboardQueries'

import { Link } from 'react-router-dom'
import { Routes } from 'Constants/Routes'
import { PageMetaTags } from 'Common'

import _ from 'lodash'
import './style.scss'
import { useCourseBundle } from 'Hooks/useCourseBundle'
import { CourseBundleItem } from 'Common/CourseBundleItem'

const Manager = ({ isAccountOwner, isZohoConnected, userId }) => {
	const { data, error, loading } = useQuery(GET_MANAGER_DASHBOARD, {
		variables: { limit: 3 },
		fetchPolicy: 'network-only',
	})

	const { courseBundleOptions, loading: isCurrentAccountLoading } = useCourseBundle()

	const complianceTooltipText = `The Click Compliance percentage is based on the number of Click courses 
	which are compliant (ie NOT overdue). 
	It only includes courses linked to active profiles in the calculation 
	and does not include sessions.`

	// statement with userId is temporary in order to check on production
	const displayBillingBlock = isAccountOwner && isZohoConnected && userId === 50634

	if (loading) return <Loader active />
	if (error) return <span>Something went wrong :(</span>

	const { nearing_completion, learners_overdue, learners_in_progress, compliance_percentage } = data.manager

	return (
		<div className="dashboard-page manager">
			<PageMetaTags title="Dashboard" />
			<Grid stackable>
				<Grid.Row columns={1}>
					<Grid.Column className="cart-wrap">
						{/* <Link to={Routes.ACTIVITY + '?active=1&progress=2'} className="compliance tile"> */}
						<Popup
							content={complianceTooltipText}
							position="bottom left"
							trigger={
								<div className="compliance tile">
									<span className="masked-icon">
										<i className="fas fa-shield masked-icon__levelOne"></i>
										<i className="fas fa-exclamation masked-icon__levelTwo"></i>
									</span>
									<span className="custom-label">Overall Click Compliance</span>
									<span className="count pl-2">{compliance_percentage}%</span>
								</div>
							}
						/>
						{/* </Link> */}
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1}>
					<Grid.Column className="cart-wrap">
						{isCurrentAccountLoading ? (
							<Loader active />
						) : courseBundleOptions.length > 1 ? (
							<div className="tabs-view">
								<Grid columns={5}>
									<Grid.Row className="course-bundle-row">
										{_.map(courseBundleOptions, bundle => (
											<CourseBundleItem key={bundle.key} bundle={bundle} />
										))}
									</Grid.Row>
								</Grid>
							</div>
						) : (
							<p>You dont have any subscription or course bundle</p>
						)}
					</Grid.Column>
				</Grid.Row>

				<Grid.Row stretched columns={3}>
					<Grid.Column className="cart-wrap">
						<Link to={Routes.ACTIVITY + '?due=4&active=1'} className="over tile">
							<i className="far fa-calendar-times" />
							<span className="count">{learners_overdue}</span>
							<span className="custom-label">Overdue</span>
						</Link>
					</Grid.Column>

					<Grid.Column className="cart-wrap">
						<Link to={Routes.ACTIVITY + '?due=3&active=1'} className="remaining tile">
							<i className="far fa-calendar-exclamation" />
							<span className="count">{nearing_completion}</span>
							<span className="custom-label">Due Soon</span>
						</Link>
					</Grid.Column>

					{/* <Grid.Column className="cart-wrap">
						<Link to={Routes.ACTIVITY + '?active=1&progress=3'} className="compliancePercent tile">
							<i className="fas fa-tachometer-alt-fast" />
							<span className="count">{compliance_percentage}</span>
							<span className="custom-label">Compliance Percentage</span>
						</Link>
					</Grid.Column> */}

					<Grid.Column className="cart-wrap">
						<Link to={Routes.ACTIVITY + '?active=1&progress=2'} className="progress tile">
							<i className="fas fa-tachometer-alt-fast" />
							<span className="count">{learners_in_progress}</span>
							<span className="custom-label">in Progress</span>
						</Link>
					</Grid.Column>
				</Grid.Row>

				<Grid.Row>
					<Grid.Column className="cart-wrap" computer={displayBillingBlock ? 12 : 16} mobile={16}>
						<Link to={Routes.GROUP_COMPLIANCE} className="compliance tile">
							<i className="far fa-clipboard-check" />
							<span className="custom-label">Compliance Reporting</span>
						</Link>
					</Grid.Column>
					<Grid.Column className="cart-wrap" computer={displayBillingBlock ? 12 : 16} mobile={16}>
						<Link to={Routes.MATRIX} className="compliance tile">
							<i className="fas fa-th-list"></i>
							<span className="custom-label">Training Matrix</span>
						</Link>
					</Grid.Column>
					{displayBillingBlock && (
						<Grid.Column className="cart-wrap" computer={4} mobile={16}>
							<a
								href="https://billing.greymatterlearning.co.uk/portal/click/login"
								className="compliance billing tile"
							>
								<i className="fas fa-coins" />
								<span className="custom-label">Billing</span>
							</a>
						</Grid.Column>
					)}
				</Grid.Row>
			</Grid>
		</div>
	)
}

Manager.propTypes = {
	isAccountOwner: PropTypes.bool,
	isZohoConnected: PropTypes.bool,
	userId: PropTypes.number.isRequired,
}
Manager.defaultProps = {
	isAccountOwner: false,
	isZohoConnected: false,
}

export default Manager
